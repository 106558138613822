/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

html {
  font-size: 16px;
  /* Changes 1rem to 18px */
}

@font-face {
  font-family: "Outfit-Regular";
  src: url(./fonts/Outfit-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Helvetica";
  src: url(./fonts/Helvetica.ttf) format("truetype");
}

@font-face {
  font-family: "Courgette-Regular";
  src: url(./fonts/Courgette-Regular.ttf) format("truetype");
}


body {
  font-family: 'Outfit-Regular', 'Helvetica', sans-serif;
}

* {

  margin: 0;
  padding: 0;


}

html,
body {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}